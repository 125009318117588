<template>
	<div class="page_container">
		<div class="page_title_bg">
			<div class="page_title_flag" />
			<div class="page_title">联系我们</div>
		</div>
		<div class="page_info_bg">
			<div class="page_info_show_bg">
				<div class="page_info_show_item">公司名称：{{info.title}}</div>
				<div class="page_info_show_item">网址：{{info.web}}</div>
				<div class="page_info_show_item">地址：{{info.address}}</div>
				<div class="page_info_show_item">电话：{{info.telephone}}</div>
				<div class="page_info_show_item">传真：{{info.fax}}</div>
				<div class="page_info_show_item">邮箱：{{info.email}}</div>
			</div>
			<div class="page_info_input_bg">
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item>
						<el-input v-model="form.name" placeholder="留言标题"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input type="textarea" :rows="3" v-model="form.content" placeholder="留言内容"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="form.email" placeholder="留言邮箱"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="form.code" placeholder="验证码"></el-input>
					</el-form-item>
					<el-form-item>
						<div class="button-container">
							<el-button class="btn" type="primary" @click="onSubmit">提交</el-button>
						</div>						 
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="page_map_bg">
			<el-image class="page_map_img" :src="imgUrl" fit="cover"></el-image>
		</div>
	</div>
</template>

<script>
import { me_info } from '@/utils/info.js';

export default {
	name: 'News',
	data() {
		return {
			form: {
				name: '',
				content: '',
				email: '',
				code: ''
			}
		};
	},
	computed:{
		info: () => me_info,
		imgUrl: () => require("@/assets/images/map.png"),
	},
	mounted() {
	
	},
	methods: {
		onSubmit(){
			this.$refs["form"].validate((valid) => {
				if (valid) {
					// alert('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		}
	},
}
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
	margin: 80px auto 0;
	.page_title_bg{
		margin-top: 32px;
		display: flex;
		align-items: center;
		.page_title_flag{
			margin-right: 5px;
			width: 22px;
			height: 64px;
			background-color: #409eff;
		}
		.page_title{
			font-size: 42px;
			color:#444444; 
			font-family:Microsoft YaHei;
			font-weight: bold;
		}
	}
	.page_info_bg{
		display: flex;
		.page_info_show_bg{
			width: 40%;
			margin: 40px 30px 50px;
			.page_info_show_item{
				margin-bottom: 30px;
				color: #000000;
				font-size: 18px;
				font-family: 'Microsoft YaHei';
				font-weight: 400;
				text-decoration: none;
				font-style: normal;
			}
		}
		.page_info_input_bg{
			width: 60%;
			margin: 40px 30px 0 0;
		}
	}
	.page_map_bg{
		margin: 0 30px 40px;
	}
}
.button-container{
	width: 100%;
	text-align: right;
	.btn{
		width: 100px;
		height: 38px;
	}
}
@media (max-width:940px) {
	.page_container{
		.page_title_bg{
			.page_title_flag{
				margin-left: 12px;
				width: 14px;
				height: 42px;
			}
			.page_title{
				font-size: 28px;
			}
		}
		.page_info_bg{
			flex-direction: column;
			.page_info_show_bg{
				width: calc(100% - 60px);
				margin-bottom: 0;
				.page_info_show_item{
					font-size: 16px;
					margin-bottom: 20px;
				}
			}
			.page_info_input_bg{
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	:deep(.el-form-item__content){
		margin: 0 30px !important;
	}
	.button-container{
		text-align: center;
	}
}
</style>